import styled, { css } from 'styled-components'

export const Container = styled.div`

   /* position: fixed;
   width: 100%;
   top:0;
   left:0; */

   position: sticky;
   top: 0;
   z-index: 10;


`;
export const Wrapper = styled.div`

   max-width: 1200px;
   margin: 0 auto;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 30px 50px 20px 50px;
   position: relative;
   box-shadow: 0 15px 10px -11px rgb(0 0 0 / 10%);
   background-color:#fff ;

   @media (max-width:500px){
      padding: 20px 20px 20px 20px;
   }

`;

export const LogoContainer = styled.div`
   img{
      width:120px
   }
`;

export const Menu = styled.ul`
   display: flex;
   align-items:center;
   gap:20px;
   list-style: none;

   @media screen and (max-width:900px){
      position:absolute ;
      top: 101%;
      left:-100%;
      background-color: #537FF9;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      height: 90vh;
      transition: all .3s ease-in-out;
      z-index:10;

      ${props => props.aparecemenu ? css`
         left:0%;
      `
      : ''}

   }


`;


export const MenuItem = styled.li`

`;

export const MenuLink = styled.a`

   font-family: Ubuntu-Medium;
   color:var(--color1);
   text-decoration:none;
   cursor: pointer;

   ${props => props.aparienciaButton && css`
     color: #fff;
     background-color: #537FF9;
     padding: 12px 15px;
     border-radius: 8px;
   `}

   @media screen and (max-width:900px){
     color:#fff;
     font-size: 19px;

     ${props => props.aparienciaButton && css`
      margin-top: 5px;
      display: block;
      background-color: #FFC400;
   `}

   }

`;

export const MobileIcon = styled.div`

   display: none;
   font-size: 30px;
   cursor:pointer;

   svg{
      fill:#537FF9
   }
   
   @media screen and (max-width:900px){
      display: flex;      
   }

`

