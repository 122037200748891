import React from 'react';

import logoblanco from '../../assets/logo-blanco-profi.svg'
import { FooterContacto, FooterContent, FooterLogo, FooterMenu, FooterWrapper } from './Footer.elements';

import { FaPhoneAlt, FaEnvelope, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
   return (
      <>
         <FooterContent>

            <FooterWrapper>

               <FooterLogo>
                  <img src={logoblanco} alt="logoblanco" />
               </FooterLogo>

               <FooterMenu>

                  <ul>
                     <li><a href="/#">INICIO</a></li>
                     <li><a href="/#formafinanciar">VENTAJAS</a></li>
                     <li><a href="/#funcionaprofi">¿CÓMO FUNCIONA?</a></li>
                     <li><a href="/#tienesdudas">CONTACTO</a></li>
                  </ul>

               </FooterMenu>

               <FooterContacto>

                  <a href="tel:+56932975573"><FaWhatsapp></FaWhatsapp>&nbsp; +56 9 3297 5573</a>

                  <a href="mailto:contacto@profi.cl"><FaEnvelope></FaEnvelope>&nbsp; contacto@profi.cl</a>

               </FooterContacto>

            </FooterWrapper>


         </FooterContent>
      </>
   );
};

export default Footer;
