import styled, { css } from 'styled-components'
import profiimagenmobilportada from '../../assets/PANTALLA-MOVIL1.png'

export const Section = styled.section`

   ${props => props.financiamiento && css`
      /* padding: 50px 0; */
      margin-bottom: 100px;
      position:relative ;
      z-index:2;

   `}
  
   ${props => props.formafinanciar && css`

      margin-bottom: 50px;
      position: relative;

      &::before {
         content: '';
         width: 420px;
         height: 420px;
         position: absolute;
         top: -200px;
         left: -200px;
         border: 88px solid #537FF9;
         opacity: 0.06;
         border-radius: 50%;
      }

   `}
  
   ${props => props.funcionaprofi && css`

      margin-bottom: 50px;
   `}
   
   ${props => props.partedeprofi && css`

      margin-bottom: 65px;
   `}
   
   ${props => props.necesitasfinanciamiento && css`

      margin-bottom: 80px;
   `}
   
   ${props => props.tienesdudas && css`

      margin-bottom: 70px;
      position: relative;

      &::before {
         content: '';
         position: absolute;
         border: 110px solid #537FF9;
         opacity: 0.09;
         top: 25%;
         width: 400px;
         left: -200px;
         height: 400px;
         border-radius: 50%;
      }

   `}

   ${props => props.masinfo && css`

      margin-bottom: 70px;
      position: relative;

      &::before {
         content: '';
         position: absolute;
         border: 110px solid #537FF9;
         opacity: 0.09;
         top: 25%;
         width: 400px;
         left: -200px;
         height: 400px;
         border-radius: 50%;
      }

   `}

   @media (max-width:500px){
      &::before {
         display:none;
      }

   }
   @media (max-width:767px){


      ${props => props.financiamiento && css`
         height: auto;
         margin-bottom: 0px;
         display:flex;
         justify-content: center;
         background-position: center 38vh;
         background-size: 300px;
         background-repeat: no-repeat;
         align-items: flex-start;
         padding: 50px 5px;

      `}
   }

`;

export const Wrapper = styled.div`
   max-width: 1200px;
   margin: 0 auto;
   

   ${props => props.funcionaprofi && css`

      max-width: initial;

   `}

   ${props => props.financiamiento && css`
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media(max-width:1200px){
         justify-content: center;
      }
      
   `}

   @media(max-width:1200px){

      ${props => props.necesitasfinanciamiento && css`
         padding: 0 20px;
      `}

   }
   
   @media(max-width:600px){

      ${props => props.necesitasfinanciamiento && css`
         padding: 0 20px;
      `}

   }
   
   
`;

export const FinanTitulo = styled.div`

   max-width: 600px;
   padding-bottom:110px ;

   h2{
      font-family: Ubuntu-Medium;
      font-size: 45px;
      color:#1A3A42;
      font-weight: normal;
      line-height: 45px;
      margin-bottom: 10px;
   }
   p{
      color:#969696;
      font-family: Ubuntu-Medium;
      font-size: 22px;
      margin-bottom: 15px;
   }

   @media(max-width:1200px){

      /* padding-left: 50px; */
      padding:0 ;
      padding-bottom: 20px;

   }

   @media(max-width:870px){
      padding-left: 20px;
      padding-bottom: 20px;
   }

   @media(max-width:767px){
      text-align:center;
      padding-left: 0px;
      padding-bottom: 20px;



   }
   
   @media(max-width:500px){

      padding-bottom: 20px;
      
      h2{
         font-family: Ubuntu-Medium;
         font-size: 38px;
         color:#1A3A42;
         font-weight: normal;
         line-height: 38px;
         margin-bottom: 10px;
      }


   }

`;

export const FinanImagen = styled.div`

   display: flex;
   position: relative;

   &::before{
      content: '';
      position: absolute;
      width: 150px;
      height: 150px;
      background-color:#537FF9 ;
      border-radius: 50%;
      left: 15px;
      top: 50%;
   }
  
   img{
      width: 600px;
      position: relative;

   }


   @media(max-width:1200px){

      img{

      }

   }
   
   @media(max-width:970px){

      img{

         width: 420px;
      }

   }

   @media(max-width:870px){

      
      img{
         position:static;
      }

      &::before {
         position: absolute;
         width: 110px;
         height: 110px;
         background-color: #537FF9;
         border-radius: 50%;
         left: 21px;
         top: 50%;
         z-index:-1;
      }

   }

   @media(max-width:767px){

      img{
         display:none;
      }

   }


   
`;



export const FinanButton = styled.a`
   color:#fff;
   font-family: Ubuntu-Medium;
   padding: 7px 15px;
   margin-left: 10px;
   border-radius: 7px;
   display: inline-block;
   cursor:pointer;
   text-decoration:none;

   background-color: ${props => props.color};

   /* ${props => props.azul && css`
      background-color:#537FF9
   `}
   
   ${props => props.amarillo && css`
      background-color:#FFC400
   `} */
   
   ${props => props.horas24 && css`
      float:right;
      margin-top: 15px;
   `} 


`

export const TituloGeneral = styled.div`

   margin-bottom: 35px;

   ${props => props.partedeprofi && css`
      margin-bottom: 20px;
   `}

   h2{
      font-family: Ubuntu-Bold;
      font-weight: 300;
      color:#3E3E3E;
      font-size: 35px;
      text-align:center;
   }
   
   p{
      
      font-family: Ubuntu-Medium;
      text-transform: uppercase;
      text-align:center;
      color:#537FF9;
      font-size: 14px;

   }

   @media (max-width:900px){

      margin-bottom: 60px;

      ${props => props.partedeprofi && css`
         margin-bottom: 0px;
      `}

   }

`
export const ForfinDatos = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;

   @media(max-width:1070px){

      padding: 0px 20px;

   }
   
   @media(max-width:870px){

      flex-direction: column;

   }


`

export const ForfinText = styled.div`
   &>div:not(:last-child){
      margin-bottom: 10px;
   }
`

export const ForfinImagen = styled.div`
   img{
      max-width: 520px;
   }

   @media(max-width:870px){
      margin-bottom: 20px;
   }
   
   @media(max-width:520px){
      img{
         width:100%
      }
   }



`
export const ForfinTextItem = styled.div` 

   display: flex;
   align-items: center;
   max-width: 450px;

   @media(max-width:870px){
      gap: 10px;
   }

`
export const ForfinTextItemtexto = styled.div`
   
   h3{
      font-family: branding-bold;
      color:#3E3E3E;
      margin-bottom: 8px;
   }
   p{
      font-family: branding-medium;
      color:#3E3E3E;

   }

   @media(max-width:400px){
      h3{
         line-height: 20px;
         margin-bottom: 5px;
      }

      p{
         font-size: 15px;
      }
   }

  

`

export const ForfinTextItemimagen = styled.div`
   position: relative;
   left: -40px;
   
   img{
      width: 130px;

   }

   @media(max-width:870px){
      position:static;
   }
   
   @media(max-width:400px){
      img{
         width: 90px;
      }
   }
`

export const ContentPasos = styled.div`

   /* height: 600px; */
   /* overflow-x: hidden; */
   position:relative;

   ${props => props.funcionaprofi && css`

      &::-webkit-scrollbar {
         display: none; 
      }

   `}

   &::before {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      right: -10%;
      height: 500px;
      width: 500px;
      border-radius: 50%;
      background-color:rgba(0, 0, 0,.06);
   }
  
   &::after {
      content: '';
      position: absolute;
      display: block;
      top: 45%;
      left: -10%;
      height: 500px;
      width: 500px;
      border: 70px solid rgba(58,207,211,.06);
      border-radius: 50%;
      z-index:-1;
   
   }

   @media(max-width:900px){

      display:none;

      overflow: visible;

      &::after {
         display:none
      }
      
      &::before {
         display:none
      }

      height: auto;
      margin-top:40px;
      padding: 0 15px;
   }

`

export const ContentPasosItems = styled.div`

   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 40px;
   gap: 20px;

   @media(max-width:900px){

      flex-direction:column;

      ${props => props.reverse && css`
         flex-direction:column-reverse;
      `}
      
      ${props => props.carruselfuncprofitems && css`
         padding-top: 30px;
      `}

      
   }

`

export const ContentPasosItemsTexto = styled.div`

   max-width: 250px;
   position: relative;
   --left: 20px;
   padding-left: 11px;
   
   &::before{
      
      content:'';
      position: absolute;
      top: 0;

      ${props => props.derecha ? css`
         left:-41px;
      `: css`
         left: var(--left);
      `}
      height: 2px;
      background-color: #537FF9;

      ${props => props.derecha ? css`
         width:113%;
      `: css`
         width:calc(113% - var(--left));
      `}
   }
   
   &::after{
      content: '${props => props.num}' ;
      position:absolute;
      top:-31px;
      ${props => props.derecha ? css`
         right:0;
      `: css`
         left:0;
      `}
      font-size: 35px;
      color:#F60F5B;
      font-family: Ubuntu-Bold;

   }

   h3{
      font-family: Ubuntu-Bold;
      color: #3E3E3E;
      font-size: 28px;
      text-align:right;
   }



   @media(max-width:900px){
      max-width: 350px;
      padding: 0;
      text-align:center;

      h3{
         text-align: center;
         margin-bottom: 15px;
      }

      &::before{
         
         width:100%;

         ${props => props.derecha && css`
            left: var(--left);
         `}
         top:-3px
      }

      &::after{

         ${props => props.derecha && css`
            top: -34px;
            right: initial;
            left: 0;
         `}

      }

      @media(max-width:500px){
         &::before{
         
            width:90%;

            ${props => props.derecha && css`
               left: var(--left);
            `}
         }
      }
      

   }

`

export const ContentPasosItemsImagen = styled.div`

   position:relative;
   z-index: 1;

   img{
      max-width: 520px;
   }

   @media(max-width: 520px){
      img{
         width:100%
      }
   }

`

export const Testimonio = styled.div`

   width: 280px;
   margin: 0 auto;

   @media(max-width:767px){
      width: 210px;
   }

`

export const TestimonioIcono = styled.div`

   position:relative;
   
   ${props => props.izquierda && css`
      left: -20px;
      top: 20px;
   `}
   
   ${props => props.derecha && css`
      left: calc(100% - 30px);
      top: 20px;
   `}
   
   width: 55px;
   height: 55px;
   display: flex;
   align-items: center;
   border-radius: 50%;
   justify-content: center;
   color: #fff;

   img{
      width:100%
   }

   @media(max-width:767px){
      display:none
   }


`

export const TestimonioTexto = styled.div`

   background: #FFFFFF;
   box-shadow: 3px 3px 6px #0000003B;
   border: 1px solid #F4F4F4;
   border-radius: 7px;
   padding: 25px 15px;


   p.parrafo{
      font-family: Ubuntu-Light;
      color:#838383;
      font-style: italic;
      text-align:center;
      margin-bottom: 15px;
      line-height: 22px;
   }
   
   p.nombres{
      color:#4D4D4D;
      font-family: branding-medium;
      text-align:center;
      strong  {
         display:block;
      }
   }

   @media(max-width:767px){
      padding: 15px 10px;

      p.parrafo{
         line-height: 19px;
         font-size: 14px;
      }
   }


`

export const NecesitasFinanciamiento = styled.div`

   max-width: 900px;
   background-color: #537FF9;
   padding:50px;
   border-radius: 59px;
   text-align:center;
   margin: 0 auto;

   h3{
      color:#fff;
      font-family: Ubuntu-Bold;
      font-size:30px;
      text-align:center;
      margin-bottom: 20px;
   }

   p{
      color:#fff;
      font-family: Ubuntu-Light;
      text-align:center;
      font-size:19px;
      margin-bottom: 10px;
      padding: 0 100px;
      }

   a{

      margin:0

   }

   @media(max-width:600px){
      padding: 30px 20px;

      h3{
         font-size: 22px;
      }

      p{
         padding:0;
         font-size: 16px;
      }
   }

`

export const TienesDudasContenido = styled.div`
   display:flex;
   align-items: center;
   justify-content: center;
   gap:90px;

   @media(max-width:990px){
      flex-direction: column;
      padding: 0 15px;
   }
`


export const TienesDudasImagen = styled.div`
   position:relative;

   img{
      width:280px
   }
`

export const TienesDudasImagenText = styled.div`

   position: absolute;
   padding:10px 18px;
   color:#fff;
   font-family: branding-medium;
   width: 200px;


   ${props => props.amarillo && css`
      background: #FFC400 0% 0% no-repeat padding-box;
      box-shadow: 3px 3px 6px #00000041;
      border-radius: 15px 0px 15px 15px;
      top: 26%;
      left: -50px;

      @media(max-width:500px){
      
         top: 12%;
         left: 0%;
      
      }

   `}
   
   ${props => props.azul && css`

      background: #537FF9 0% 0% no-repeat padding-box;
      box-shadow: 3px 3px 6px #00000041;
      border-radius: 0px 15px 15px 15px;
      top: 50%;
      right: -60px;

      @media(max-width:500px){
      
         top: 41%;
         right: 0;
   
      }

   `}
   
   ${props => props.rojizo && css`
      background: #F60F5B 0% 0% no-repeat padding-box;
      box-shadow: 3px 3px 6px #00000041;
      border-radius: 15px 0px 15px 15px;
      top: 70%;
      left: -60px;

      @media(max-width:500px){

         top: 71%;
         left: 0;


      }
   `}
`

export const TienesDudasFormulario = styled.div`

   max-width: 470px;

   h2{
      color:#3E3E3E;
      font-family: Ubuntu-Bold;
      font-size: 40px;
      line-height: 40px;
      margin-bottom:20px;
   }
   ul{
      
    list-style: none;

   }

   li{
      color:#808080;
      font-family: branding-bold;   
       margin-bottom: 10px;
       display: flex;
       align-items: center;
       gap:10px;

   }

   li:not(:last-child) svg{
      font-size: 30px;
      fill: #537FF9;
   }
   
   li:last-child svg{
      font-size: 30px;
      fill: #537FF9;
      
   }

   a{
      font-family: branding-bold;
      color:#537FF9;
      text-decoration: none;
   }

   form{
      margin-top:20px;
   }

   .tienesdudas_formulario-inputs{
      display: flex;
      flex-wrap: wrap;
      gap:10px;
      margin-bottom:20px;
   }

   .tienesdudas_formulario-inputs input{
      width:200px;
      background: #EFF0F0 0% 0% no-repeat padding-box;
      border: 1px solid #BFBFBF;
      border-radius: 10px;
      outline: none;
      padding: 11px;
      font-family: Ubuntu-Medium;
   }
   button{
      outline:none;
      background-color: #FFC400 ;
      color:#fff;
      border: none;
      font-family: Ubuntu-Medium;
      padding: 7px 35px;
      border-radius: 7px;
      font-size: 14px;
      cursor:pointer;
   }

   @media(max-width:520px){
      h2{
         font-size: 30px;
         line-height: 30px;
         text-align:center
      }
   }

   @media(max-width:439px){
      .tienesdudas_formulario-inputs input{
         width:100%
      }
      
      button{
         padding: 10px 35px;
         width:100%
         
      }

   }

  

`


