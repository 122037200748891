import React, { useEffect } from 'react';
import { getFintoc } from '@fintoc/fintoc-js';

function IngresaBanco() {

    const initFintoc = async () => {
        const options = {
            holderType: 'business',
            product: 'movements',
            webhookUrl: 'https://us-central1-profi-d1de4.cloudfunctions.net/nuevo_link',
            publicKey: 'pk_live_yPTeAXH13Msa5tEmwSLzE-oZG4P4SZxy',
        }
        const Fintoc = await getFintoc();
        const widget = Fintoc.create(options);
        widget.open();
    }

    useEffect(() => {
        initFintoc()
    }, [])
    return (
        <>
        </>
    )
}


export default IngresaBanco;