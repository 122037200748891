exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./fonts/Ubuntu-Bold.ttf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./fonts/Ubuntu-Light.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./fonts/Ubuntu-Medium.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./fonts/Branding-SF-Bold.otf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./fonts/Branding-SF-Medium.otf"));

// Module
exports.push([module.id, "html{\n  scroll-behavior: smooth;\n  scroll-padding-top: 120px;\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow-x: hidden;\n}\n\n*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n:root{\n  --color1:#1A3A42\n}\n\n/* funetes */\n\n@font-face {\n  font-family: Ubuntu-Bold;\n  src: url(" + ___CSS_LOADER_URL___0___ + ");\n}\n\n@font-face {\n  font-family: Ubuntu-Light;\n  src: url(" + ___CSS_LOADER_URL___1___ + ");\n}\n\n@font-face {\n  font-family: Ubuntu-Medium;\n  src: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n\n@font-face {\n  font-family: branding-bold;\n  src: url(" + ___CSS_LOADER_URL___3___ + ");\n}\n\n@font-face {\n  font-family: branding-medium;\n  src: url(" + ___CSS_LOADER_URL___4___ + ");\n}", ""]);

