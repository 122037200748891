import styled from 'styled-components'
import fondofooter from '../../assets/fondo-footer.svg'

export const FooterContent = styled.footer`
   background-image:url(${fondofooter}) ;
   width: 100%;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;

` ;

export const FooterWrapper = styled.div`
   padding: 50px 0;
   max-width:900px;
   margin: 0 auto;
` ;

export const FooterLogo = styled.div`
   
   text-align:center;

   margin-bottom:20px;

   img{
      width: 100px;
   }

` ;

export const FooterMenu = styled.div`
   padding-bottom: 20px;
   margin-bottom: 20px;
   border-bottom:1px solid #fff;

   ul{
      list-style:none;
      display:flex;
      align-items: center;
      justify-content: center;
      gap: 8%;
   }

   li a{
      text-decoration:none;
      color:#fff;
      font-family: Ubuntu-Medium;
      
   }

   @media(max-width:520px){

      ul{
         flex-direction:column;
         gap: 20px;
      }

   }

   ` ;

export const FooterContacto = styled.div`

   display: flex;
   justify-content: center;
   align-items: center;
   gap: 50px;

   a{
      font-family: Ubuntu-Medium;
      text-decoration:none;
      color:#fff;
      display: inline-flex;
      align-items: center;
   }


   @media(max-width:520px){

      flex-direction: column;
      gap: 15px;

   }

` ;
