import React, { useState } from 'react';
import {
   Container,
   LogoContainer,
   Menu,
   MenuItem,
   MenuLink,
   MobileIcon,
   Wrapper
} from './Navbar.elements';

import logoprofi from '../../assets/logoprofi.svg'
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = ({ isScroll, abrirModalCons }) => {

   const [showmenu, setShowmenu] = useState(false)

   const handleMenuMobilShow = () => {
      setShowmenu(!showmenu)
   }



   return (
      <Container id='navbar' isScroll={isScroll}>

         <Wrapper>

            <LogoContainer>
               <a href="/"><img src={logoprofi} alt="" /></a>
            </LogoContainer>

            <MobileIcon onClick={handleMenuMobilShow}>
               {
                  !showmenu ? (<FaBars />) : (<FaTimes />)
               }
            </MobileIcon>

            <Menu aparecemenu={showmenu}>

               <MenuItem>
                  <MenuLink
                     onClick={handleMenuMobilShow}
                     href="/#financiamiento">INICIO</MenuLink>
               </MenuItem>
               <MenuItem>
                  <MenuLink
                     onClick={handleMenuMobilShow}
                     href="/#formafinanciar">VENTAJAS</MenuLink>
               </MenuItem>
               <MenuItem >
                  <MenuLink
                     onClick={handleMenuMobilShow}
                     href="/#funcionaprofi">¿CÓMO FUNCIONA?</MenuLink>
               </MenuItem>
               {/*<MenuItem>
                  <MenuLink
                     onClick={handleMenuMobilShow}
                     href="/#partedeprofi">CLIENTES</MenuLink>
               </MenuItem>*/}
               <MenuItem>
                  <MenuLink
                     onClick={handleMenuMobilShow}
                     href="/#tienesdudas">CONTACTO</MenuLink>
               </MenuItem>
               <MenuItem>
                  <MenuLink aparienciaButton href="https://app.profi.cl/">COMENZAR</MenuLink>
               </MenuItem>

            </Menu>

         </Wrapper>


      </Container >
   );
};

export default Navbar;
