import React from 'react'

import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

import styled, { css } from 'styled-components'

const Container = styled.div`
   max-width:1000px ;
   margin: 50px auto ;

`
const Negrita = styled.strong`

   font-family: Ubuntu-Bold;
   color: #3E3E3E;

`

const Parrafo = styled.p`

   padding:initial ;
   margin: 1em 0;
   font-family: branding-medium;
    color: #3E3E3E;
   
   ${props => props.center && css`
      text-align:center ;
   `}

`

const TerminosCondiciones = () => {
   return (
      <>
         <Navbar></Navbar>

         <Container>
            <Parrafo center>
               <Negrita >TÉRMINOS Y CONDICIONES DEL USO DEL SITIO WEB Y POLÍTICA COMERCIAL DE PROFI  </Negrita>
            </Parrafo>
            <Parrafo>Bienvenido al sitio web de Profi Spa, Rut 77.517.269-K, representada legalmente por Germán Quappe Vatter, con domicilio en la comuna de Las Condes (en adelante, “Profi”). Al ingresar a este sitio web y hacer click en este documento el Usuario acepta y reconoce los presentes términos y condiciones del uso de este sitio web y la política comercial de Profi, en adelante, los "Términos y Condiciones", que deberán ser cumplidos por los usuarios de la página web de Profi (en adelante, el “Usuario”) y/o quienes contraten los productos ofrecidos por Profi (en adelante, el “Cliente”).</Parrafo>

            <Parrafo>Hacemos presente que los productos de Profi no están sujetos a la fiscalización del Banco Central de Chile o la Comisión para el Mercado Financiero.</Parrafo>

            <Parrafo>
               <Negrita>1.	DEL FINANCIAMIENTO PROFI</Negrita>
            </Parrafo>

            <Parrafo>A.	Profi ofrece financiamiento a medida a personas jurídicas constituidas en Chile que cumplan las condiciones señaladas en el número 2 de este documento, atendiendo al perfil de cada Usuario, en adelante, “Financiamiento Profi”.</Parrafo>

            <Parrafo>B.	Para que Profi pueda realizar una oferta de financiamiento, el Usuario deberá crear una cuenta registrándose en la página web de Profi (www.profi.cl), en adelante, “Cuenta Profi” y acompañar los siguientes antecedentes, los que serán evaluados por Profi, reservándose el derecho a pedir antecedentes adicionales:
            </Parrafo>

            <Parrafo>
               i.	Certificado que acredite la vigencia de la sociedad.<br />
               ii.	Certificado que de cuenta de los poderes vigentes.
            </Parrafo>

            <Parrafo>
               C.	La Cuenta Profi no tiene ningún costo asociado y es personal, única e intransferible. No será posible crear más de una cuenta asociada al mismo Usuario o potencial Cliente o ceder o transferir su cuenta de modo alguno a otra persona. Podremos cancelar, suspender o inhabilitar las Cuentas Profi que contengan datos coincidentes o relacionados, o en caso de sospecha fundada de fraude o actividad ilícita, sin autorización previa. Al aceptar esta política el Usuario se obliga a mantener la confidencialidad de los datos asociados a su cuenta y no revelar su clave de acceso.
            </Parrafo>

            <Parrafo>
               D.	Para que Profi pueda hacer una evaluación de riesgo del Usuario, este deberá entregar acceso a través de la plataforma Fintoc (API Fintoc) a su información financiera y bancaria disponible en la página web de su banco y a la información tributaria disponible en la página web del Servicio de Impuestos Internos. Para estos efectos, una vez aceptados los términos y condiciones y política de privacidad propia de Fintoc, es el Usuario quien incorpora directamente a través de la API Fintoc sus claves de acceso tanto a su banco como a su cuenta del Servicio de Impuestos Internos. Fintoc es totalmente independiente a Profi, y es la entidad que provee la infraestructura necesaria para conectar a Profi con la información contenida en las cuentas bancarias y del Servicio de Impuestos Internos de los Usuarios, de modo que es importante que el Usuario lea y esté de acuerdo con los términos y condiciones de sus servicios y política de privacidad. En ningún caso Profi tendrá acceso a las credenciales de acceso a las cuentas bancarias o a las credenciales de acceso a la cuenta del Servicio de Impuestos Internos de los Usuarios ni de los Clientes. Profi tampoco se hace responsable de interrupciones en el servicio del banco respectivo, del Servicio de Impuestos Internos o de Fintoc.
            </Parrafo>

            <Parrafo>
               E.	El proceso de evaluación del riesgo del Usuario busca determinar la capacidad de pago del Usuario  y podría incluir además de la información de transacciones bancarias y aquella que se encuentre disponible en el Servicio de Impuestos Internos, información relativa a protestos y morosidades, órdenes de no pago de cheques, empresas en las que puedas tener participación, los activos del potencial Cliente, garantías otorgadas, quiebras, liquidación de bienes, ingresos y productos bancarios de cualquier otra fuente de información de acceso público.
            </Parrafo>

            <Parrafo>
               F.	En caso de que Profi ofrezca un financiamiento al Usuario, los términos de dicho financiamiento (tasa de interés, plazo, etc.) serán los comprendidos en la simulación que se pondrá a disposición del Usuario en su Cuenta Profi y que se confecciona según el propio perfil de riesgo del Usuario. Esta simulación podría ser actualizada conforme se actualicen antecedentes del Usuario que modifiquen su perfil de riesgo.
            </Parrafo>

            <Parrafo>
               G.	Para la entrega del Financiamiento Profi, el Usuario debe aceptar pura y simplemente los términos de la simulación entregada por Profi y deberá suscribir mediante firma electrónica avanzada un acuerdo de financiamiento y un mandato especial irrevocable para el llenado de las menciones de un pagaré y su suscripción. Dicho pagaré tendrá la exclusiva finalidad de garantizar el pago del financiamiento según los términos acordados y el impuesto de timbres y estampillas correspondiente. Estos documentos serán puestos a disposición del Usuario en su Cuenta Profi oportunamente. Profi se reserva el derecho de solicitar en cualquier momento al Cliente suscribir el pagaré mediante firma manuscrita si así lo considera necesario.
            </Parrafo>

            <Parrafo>
               H.	Una vez aceptados y suscritos los documentos mencionados en la letra anterior, Profi pondrá el financiamiento a disposición del Cliente en su cuenta corriente bancaria dentro del plazo máximo de 5 días hábiles.
            </Parrafo>

            <Parrafo>
               I.	El Cliente podrá pagar las cuotas del Financiamiento Profi a través de transferencia bancaria o depósito en la cuenta corriente  indicada para esos efectos en su Cuenta Profi o a través de los medios de pago habilitados en la misma. Los pagos efectuados a través de cualquier pasarela de pago actual o que Profi implemente en el futuro, se regirán por los términos y condiciones de uso de cada una de aquellas plataformas, por lo que el Cliente debe leer y aceptar aquellos antes de utilizar estas plataformas.
            </Parrafo>

            <Parrafo>
               <Negrita>2.	CONDICIONES PARA LA ENTREGA DE UN FINANCIAMIENTO PROFI</Negrita>
            </Parrafo>

            <Parrafo>A.	Ser una persona jurídica vigente constituida en Chile.</Parrafo>
            <Parrafo>B.	Tener al menos una cuenta bancaria vigente en Chile y acceso a ella mediante medios electrónicos.</Parrafo>
            <Parrafo>C. 	Tener poderes vigentes para la suscripción del acuerdo de financiamiento y el mandato especial irrevocable que se pondrá a disposición del Usuario en su Cuenta Profi.</Parrafo>
            <Parrafo>D.  	Aceptar la presente política comercial y política de privacidad de Profi (https://profi.cl/politica-de-privacidad), firmar el acuerdo de financiamiento y el pagaré mediante firma electrónica y firmar el mismo pagaré mediante firma manuscrita en caso de ser requerido.</Parrafo>
            <Parrafo>
               <Negrita> 3.	      DE LA SEGURIDAD DEL SITIO WEB</Negrita>
            </Parrafo>
            <Parrafo>Profi hará sus mejores esfuerzos para ofrecer el mejor nivel de seguridad para los usuarios que lo consulten, para la cual el sitio web cuenta con tecnología que permite cifrar el tráfico de datos, impidiendo que éstos puedan ser interceptados. Profi no se hace responsable de daños ocasionados a Usuarios y/o Clientes por el mal uso de su Cuenta Profi o por errores o incidencias relacionadas al uso de Fintoc. La instalación al enlace de dicho proveedor no esta bajo el control de Profi.</Parrafo>
            <Parrafo>De esta manera, se deja expresamente establecido que Profi no responderá por el daño directo, indirecto, previsto o imprevisto, o cualquier otro que eventualmente provoque al sistema de tratamiento de la información del Usuario y/o Cliente ocasionada por el mal uso de la Cuenta Profi o por terceros.</Parrafo>
            <Parrafo>
               <Negrita>4. OBLIGACIONES DEL USUARIO</Negrita>
            </Parrafo>
            <Parrafo>Quien navegue en www.profi.cl se obliga, sin reservas ni restricciones, a:</Parrafo>
            <Parrafo>A.	Hacer uso correcto del sitio web, sus servicios y contenidos bajo su exclusiva responsabilidad, respetando los Términos y Condiciones que regulan al mismo, la legislación vigente y el orden público.</Parrafo>
            <Parrafo>B	Privarse de reproducir, copiar, comercializar, ceder, recircular, retransmitir, distribuir, publicar o modificar los contenidos.</Parrafo>
            <Parrafo>C.	Responder de todo daño y/o perjuicio, de cualquier naturaleza, que pueda sufrir Profi como consecuencia del incumplimiento de cualquier obligación a las que queda sometido el Usuario por los Términos y Condiciones.</Parrafo>
            <Parrafo>
               D.	 Garantizar y responder acerca de la veracidad, exactitud, vigencia y autenticidad de los datos personales proporcionados, comprometiéndose a mantenerlos debidamente actualizados.
            </Parrafo>
            <Parrafo>
               <Negrita>5.  DE LA PROPIEDAD INTELECTUAL</Negrita>
            </Parrafo>
            <Parrafo>
               A.	Todo lo relacionado con el sitio web y su contenido, así como el sistema de software, bases de datos, y archivos que permiten al Usuario el acceso y uso de sus cuentas, son propiedad exclusiva de Profi y están protegidas bajo derecho de reproducción, marca y o ley de patentes y tratados internacionales relacionados.
            </Parrafo>
            <Parrafo>B.	Profi no otorga ninguna licencia o autorización de uso de ninguna clase o especie sobre sus legítimos derechos de propiedad industrial o intelectual, así como sobre cualquier derecho relacionado con el sitio web y su contenido, sean estos presentes o futuros.</Parrafo>
            <Parrafo>
               <Negrita>6.   DE LA JURISDICCIÓN COMPETENTE, LEY APLICABLE y DOMICILIO</Negrita>
            </Parrafo>
            <Parrafo>A.	El uso del sitio web, así como los Términos y Condiciones, serán regidos e interpretados de conformidad con las leyes de la República de Chile, y cualquier duda o dificultad que surja en relación con éstas, se somete a la competencia de los tribunales ordinarios de justicia. Para estos efectos el Usuario y Profi fijan su domicilio en la ciudad y comuna de Santiago.</Parrafo>
            <Parrafo>
               <Negrita>7.    CONTACTO PROFI</Negrita>
            </Parrafo>
            <Parrafo>
               El Usuario o Cliente que necesite reportar cualquier violación de los Términos y Condiciones o que tenga alguna duda o comentario respecto a las mismas, podrá hacerlo a través del correo electrónico: contacto@profi.cl
            </Parrafo>

         </Container>

         <Footer></Footer>
      </>
   )
}

export default TerminosCondiciones