import React from 'react';

import { Section, Wrapper, FinanButton, FinanImagen, FinanTitulo, TituloGeneral, ForfinDatos, ForfinText, ForfinImagen, ForfinTextItem, ForfinTextItemtexto, ForfinTextItemimagen, ContentPasosItems, ContentPasosItemsTexto, ContentPasos, ContentPasosItemsImagen, Testimonio, TestimonioIcono, TestimonioTexto, NecesitasFinanciamiento, TienesDudasContenido, TienesDudasImagen, TienesDudasImagenText, TienesDudasFormulario } from './Home.elements';
import { Helmet } from "react-helmet";


// import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Navigation } from 'swiper';
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'
// import 'swiper/components/pagination';
// Styles must use direct files imports
import 'swiper/swiper.min.css'; // core Swiper
import 'swiper/modules/pagination/pagination.min.css'; // Navigation module
import 'swiper/modules/navigation/navigation.min.css'; // Navigation module

import { BsCheckCircle } from "react-icons/bs";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import finimg1 from '../../assets/PANTALLA-MOVIL1.png'

import forfinimg from '../../assets/forfiimagen1.png'
import siempreactualizado from '../../assets/siempreactualizado.svg'
import sinpapeleos from '../../assets/sinpapeleos.svg'
import menos1hora from '../../assets/menos1hora.svg'
// import funcionaimagen from '../../assets/funcionaimagen.png'
import tienesdudasimagen from '../../assets/tienesdudasimagen.svg'

import personprofiazul from '../../assets/personprofiazul.svg'
import personprofiamarillo from '../../assets/personprofiamarillo.svg'
import personprofirojizo from '../../assets/personprofirojizo.svg'
import creacuenta from '../../assets/login.png'
import ingresadatos from '../../assets/update.png'
import soli from '../../assets/soli.png'
import result from '../../assets/result.png'
import movi from '../../assets/img_inicio.png'

import TagManager from 'react-gtm-module'


// import en24horas from '../../assets/en24horas.svg'

import './index.css'

const tagManagerArgs = {
   dataLayer: {
      event: 'formularioenviado'
   }
}

const Home = ({ abrirModalCons }) => {

   const ocultar = true;
   const handleSubmit = (ev) => {

      ev.preventDefault()
      console.log(ev)
      TagManager.dataLayer(tagManagerArgs)
      // window.dataLayer.push({
      //    'event': 'new_subscriber',
      //    });
      let formData = new FormData(ev.target)

      fetch("https://www.somosjam.cl/wp-json/jm/v1/profisendemail", {
         method: "POST",
         body: formData
      })
         .then(response => response.json())
         .then(result => {
            if (result.correo) {

               toast.success('Mensaje enviado.', {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               });

            }

         })

   };

   return (
      <>

         <Helmet>
            <title>La herramienta más simple para gestionar las finanzas de tu PYME</title>
            <meta name="facebook-domain-verification" content="rif4rsh8gcqrn2r2z8teptldhgic08" />
         </Helmet>

         <Section id="financiamiento" financiamiento>
            <Wrapper financiamiento>

               <FinanTitulo>
                  <h2>Ordena las finanzas de tu PYME</h2>
                     <p><BsCheckCircle></BsCheckCircle> Controla tus facturas por cobrar y por pagar</p>
                     <p><BsCheckCircle></BsCheckCircle> Sincronización automática con el SII y tu banco</p>
                     <p><BsCheckCircle></BsCheckCircle> Te notificamos cuando recibas un pago</p>
                     <p><BsCheckCircle></BsCheckCircle> Alerta inteligente de factura rechazada</p>
                     <p><BsCheckCircle></BsCheckCircle> Estimación de IVA, ventas, compras, flujo de caja y más</p>
                     <p><BsCheckCircle></BsCheckCircle> Planes desde sólo UF 0,2 + IVA /mes</p>
                     <p><BsCheckCircle></BsCheckCircle> Activa tu cuenta en minutos y prueba gratis por un mes</p>
                  <FinanButton href="https://app.profi.cl/registrarse" color="#537FF9">PRUEBA GRATIS</FinanButton>
                  {/*<FinanButton href="/#tienesdudas" color="#FFC400">Contáctanos</FinanButton>*/}
               </FinanTitulo>

               <FinanImagen>
                  <img src={movi} primero="true" alt="telefono1" />
                  {/* <img src={finimg2} segundo="true" alt="telefono2" /> */}
               </FinanImagen>
            </Wrapper>
         </Section>

         <Section id="formafinanciar" formafinanciar>
            <Wrapper>
               <TituloGeneral>
                  <p>¿Por qué preferirnos?</p>
                  <h2>La herramienta más simple para controlar las finanzas de tu PYME</h2>
               </TituloGeneral>

               <ForfinDatos>

                  <ForfinImagen>
                     <img src={forfinimg} alt="forfinimg" />

                  </ForfinImagen>

                  <ForfinText>
                     <ForfinTextItem>

                        {/*<ForfinTextItemimagen>
                           <img src={siempreactualizado} alt="" />
                        </ForfinTextItemimagen>*/}

                        <ForfinTextItemimagen>
                           <table><tr><td>   </td></tr></table>
                        </ForfinTextItemimagen>

                        <ForfinTextItemtexto>
                           <h3>Simple y efectiva</h3>
                           <p>Diseñada especialmente para tu PYME, ordena tus pagos y ahorra tiempo.</p>
                        </ForfinTextItemtexto>

                     </ForfinTextItem>

                     <ForfinTextItem>

                        {/*<ForfinTextItemimagen>
                           <img src={sinpapeleos} alt="" />
                        </ForfinTextItemimagen>*/}

                        <ForfinTextItemimagen>
                           <table><tr><td>   </td></tr></table>
                        </ForfinTextItemimagen>

                        <ForfinTextItemtexto>
                           <h3>Actualización automática</h3>
                           <p>Ya no tienes que entrar al SII para conocer el estado de tus facturas.</p>
                        </ForfinTextItemtexto>

                     </ForfinTextItem>

                     <ForfinTextItem>

                        <ForfinTextItemimagen>
                           <table><tr><td>   </td></tr></table>
                        </ForfinTextItemimagen>

                        <ForfinTextItemtexto>
                           <h3>Justo lo que necesitas</h3>
                           <p>Al precio que necesitas. No pagas por funciones extra que nunca utilizas.</p>
                        </ForfinTextItemtexto>

                     </ForfinTextItem>

                     <ForfinTextItem>

                        {/*<ForfinTextItemimagen>
                           <img src={menos1hora} alt="" />
                        </ForfinTextItemimagen>*/}

                        <ForfinTextItemimagen>
                           <table><tr><td>   </td></tr></table>
                        </ForfinTextItemimagen>

                        <ForfinTextItemtexto>
                           <h3>Acceso desde todos tus dispositivos</h3>
                           <p>Siempre actualizado, en cualquier momento y en cualquier lugar.</p>
                        </ForfinTextItemtexto>

                     </ForfinTextItem>

                  </ForfinText>


               </ForfinDatos>

            </Wrapper>

         </Section>

         <Section id="funcionaprofi" funcionaprofi>
            <Wrapper funcionaprofi>
               <TituloGeneral funcionaprofi>
                  <p>UNA HERRAMIENTA SIMPLE Y EFECTIVA, A UN PRECIO JUSTO</p>
                  <h2>¿Cómo funciona Profi?</h2>
               </TituloGeneral>

               <ContentPasos funcionaprofi>
                  <ContentPasosItems>

                     <ContentPasosItemsTexto num="1">
                        <h3>Crea tu cuenta y prueba las funcionalidades sin costo</h3>
                     </ContentPasosItemsTexto>

                     <ContentPasosItemsImagen>
                        <img src={creacuenta} alt="" />

                     </ContentPasosItemsImagen>


                  </ContentPasosItems>

                  <ContentPasosItems reverse>

                     <ContentPasosItemsImagen>
                        <img src={ingresadatos} alt="" />
                     </ContentPasosItemsImagen>

                     <ContentPasosItemsTexto derecha num="2">
                        <h3>Conecta con el SII para sincronizar tus facturas</h3>
                     </ContentPasosItemsTexto>



                  </ContentPasosItems>

                  <ContentPasosItems>

                     <ContentPasosItemsTexto num="3">
                        <h3>Comienza a gestionar tus cuentas y ahorrar en capital de trabajo</h3>
                        <FinanButton horas24 href="https://app.profi.cl/registrarse" color="#3ACFD3" onClick={(ev) => {
                           //ev.preventDefault()
                           //abrirModalCons(false)
                        }}>Comenzar</FinanButton>
                     </ContentPasosItemsTexto>

                     <ContentPasosItemsImagen>
                        <img src={result} alt="" />

                     </ContentPasosItemsImagen>

                  </ContentPasosItems>

               </ContentPasos>

               <Swiper
                  modules={[Pagination]}
                  className='carruselfuncionaprofi'
                  spaceBetween={0}
                  slidesPerView={1}
                  loop={true}
                  centeredSlides={true}
                  onSlideChange={() => { }}
                  onSwiper={(swiper) => {
                  }}
                  pagination={{ clickable: true }}

               >
                  <SwiperSlide>

                     <ContentPasosItems carruselfuncprofitems>

                        <ContentPasosItemsTexto num="1">
                           <h3>Crea tu cuenta y prueba las funcionalidades sin costo</h3>
                        </ContentPasosItemsTexto>

                        <ContentPasosItemsImagen>
                           <img src={creacuenta} alt="" />

                        </ContentPasosItemsImagen>


                     </ContentPasosItems>

                  </SwiperSlide>

                  <SwiperSlide>

                     <ContentPasosItems reverse carruselfuncprofitems>

                        <ContentPasosItemsImagen>
                           <img src={ingresadatos} alt="" />
                        </ContentPasosItemsImagen>

                        <ContentPasosItemsTexto derecha num="2">
                           <h3>Configura tu perfil y conecta con el SII</h3>
                        </ContentPasosItemsTexto>

                     </ContentPasosItems>

                  </SwiperSlide>

                  <SwiperSlide>

                     <ContentPasosItems carruselfuncprofitems>

                        <ContentPasosItemsTexto num="3">
                           <h3>Comienza a ordenar tus cuentas y ahorrar en capital de trabajo</h3>
                           <FinanButton href="https://app.profi.cl/registrarse" color="#3ACFD3" onClick={(ev) => {
                              //ev.preventDefault()
                              //abrirModalCons(false)
                           }}>Comenzar</FinanButton>
                        </ContentPasosItemsTexto>

                        <ContentPasosItemsImagen>
                           <img src={result} alt="" />

                        </ContentPasosItemsImagen>

                     </ContentPasosItems>

                  </SwiperSlide>

                  {/* <div class="swiper-pagination"></div> */}

               </Swiper>



            </Wrapper>
         </Section>
         {
            !ocultar &&

            <Section id="partedeprofi" partedeprofi>

               <Wrapper>
                  <TituloGeneral partedeprofi>
                     <p>CLIENTES</p>
                     <h2>Ellos son parte de Profi</h2>
                  </TituloGeneral>

                  <Swiper
                     modules={[Pagination, Navigation]}
                     pagination={{ clickable: true }}
                     className='carruselpartedeprofi'
                     spaceBetween={30}
                     slidesPerView={3}
                     loop={true}
                     autoplay={{

                        delay: 4000,
                        disableOnInteraction: false,

                     }}
                     centeredSlides={true}
                     onSlideChange={() => { }}
                     onSwiper={(swiper) => {
                     }}
                     navigation
                     breakpoints={
                        {
                           // when window width is >= 320px
                           200: {
                              autoplay: {
                                 delay: 4000,
                                 disableOnInteraction: false,
                              },
                              slidesPerView: 1,
                              spaceBetween: 0,
                              centeredSlides: false,
                           },
                           // when window width is >= 480px
                           480: {
                              autoplay: {
                                 delay: 4000,
                                 disableOnInteraction: false,
                              },
                              slidesPerView: 2,
                              spaceBetween: 0,
                              centeredSlides: false,
                           },
                           // when window width is >= 640px
                           640: {
                              autoplay: {
                                 delay: 4000,
                                 disableOnInteraction: false,
                              },
                              slidesPerView: 2,
                              spaceBetween: 0,
                              centeredSlides: false,
                           },

                           1060: {
                              slidesPerView: 3,
                              spaceBetween: 0,
                           }

                        }
                     }

                  >
                     <SwiperSlide>

                        <Testimonio>

                           <TestimonioIcono className='icono' derecha color='#FFC400'>
                              <img src={personprofiamarillo} alt="" />
                           </TestimonioIcono>

                           <TestimonioTexto className='testimoniotexto'>

                              <p className="parrafo">Encontré a Profi después de intentar con varias alternativas sin resultado alguno. El proceso fue fácil y rápido, sin papeleos ni burocracia, y en 2 horas tuve el dinero en mi cuenta. Ya voy por el tercer financiamiento, gracias a Profi mi empresa está creciendo ya que nos ayudó cuando nadie más lo hacía.</p>
                              <p className="nombres">
                                 <strong>Alexander Contreras</strong>
                                 Matisis Spa, Valparaíso
                              </p>

                           </TestimonioTexto>

                        </Testimonio>

                     </SwiperSlide>

                     <SwiperSlide>

                        <Testimonio>

                           <TestimonioIcono className='icono' derecha color='#537FF9'>
                              <img src={personprofiazul} alt="" />
                           </TestimonioIcono>

                           <TestimonioTexto className='testimoniotexto'>
                              <p className="parrafo">Profi ofrece un excelente servicio de apoyo a las Pymes. Rápido, sin burocracdia, confiable y en línea. Lo recomiendo totalmente!</p>
                              <p className="nombres">
                                 <strong>Hardy Guarda</strong>
                                 Grunguard Automotriz, Osorno
                              </p>
                           </TestimonioTexto>

                        </Testimonio>

                     </SwiperSlide>
                     
                     <SwiperSlide>

                        <Testimonio>

                           <TestimonioIcono className='icono' derecha color='#FFC400'>
                              <img src={personprofirojizo} alt="" />
                           </TestimonioIcono>

                           <TestimonioTexto className='testimoniotexto'>

                              <p className="parrafo">Cuando una es una PYME siempre necesitas apoyo. Y en PROFI lo encontramos, además su plataforma es amigable, segura y si tienes alguna duda siempre te la resuelven rápido, seguiremos trabajando con ellos y es 100% recomendable.</p>
                              <p className="nombres">
                                 <strong>Paula Valdivia</strong>
                                 Camarón Sushi y Cevichería, La Florida
                              </p>

                           </TestimonioTexto>

                        </Testimonio>

                     </SwiperSlide>

                     <SwiperSlide>

                        <Testimonio>

                           <TestimonioIcono className='icono' derecha color='#FFC400'>
                              <img src={personprofiamarillo} alt="" />
                           </TestimonioIcono>

                           <TestimonioTexto className='testimoniotexto'>

                              <p className="parrafo">Como empresa no nos daban financiamiento en ningún lado hasta que conocimos Profi. Nos ayudaron en un momento difícil y con facilidades de pago. Una gestión transparente y ágil.</p>
                              <p className="nombres">
                                 <strong>Miguel Aburto</strong>
                                 Colegio Virtual Magallanes, Punta Arenas
                              </p>

                           </TestimonioTexto>

                        </Testimonio>

                     </SwiperSlide>

                     <SwiperSlide>

                        <Testimonio>

                           <TestimonioIcono className='icono' derecha color='#537FF9'>
                              <img src={personprofiazul} alt="" />
                           </TestimonioIcono>

                           <TestimonioTexto className='testimoniotexto'>
                              <p className="parrafo">Todo el proceso con Profi, desde la creación de la cuenta hasta la solicitud y obtención del financiamiento es super sencillo y confiable. Es una excelente herramienta para gestionar y obtener liquidez.</p>
                              <p className="nombres">
                                 <strong>Daniela Barboza</strong>
                                 Amoblados Santa María, Santiago
                              </p>
                           </TestimonioTexto>

                        </Testimonio>

                     </SwiperSlide>

                  </Swiper>

               </Wrapper>

            </Section>
         }


         <Section necesitasfinanciamiento>
            <Wrapper necesitasfinanciamiento>
               <NecesitasFinanciamiento>

                  <h3>
                     Pruébalo ahora, sin compromisos
                  </h3>

                  <p>
                     <strong>Crear tu cuenta es gratis</strong> y podrás probar las funcionalidades sin costo antes de contratar.
                  </p>

                  <FinanButton href="https://app.profi.cl/registrarse" color="#FFC400">Registrarme</FinanButton>

               </NecesitasFinanciamiento>
            </Wrapper>
         </Section>

         <Section id="masinfo" masinfo>

            <Wrapper>
               <TituloGeneral partedeprofi>
                  <p style={{fontSize: '18px'}}>Gestión de cuentas por cobrar y por pagar</p>
                  <h3 style={{fontWeight: 'normal'}}><br></br>Accede desde cualquier dispositivo a una lista de las facturas emitidas a crédito, que se alimenta automáticamente con datos del SII. Para que puedas gestionar efectivamente la caja de tu empresa, optimizando la liquidez.</h3>
                  <h3 style={{fontWeight: 'normal'}}><br></br>Cobra a tus clientes en forma efectiva y no dejes cuentas pendientes sin gestionar.</h3>
                  <h3 style={{fontWeight: 'normal'}}><br></br>Paga a tus proveedores dentro de lo acordado, manteniendo una buena relación y evitando duplicar pagos.<br></br></h3>
               </TituloGeneral>

               <TituloGeneral partedeprofi>
                  <p style={{fontSize: '18px'}}>Facturas emitidas</p>
                  <h3 style={{fontWeight: 'normal'}}><br></br>Recibe notificaciones cada vez que cambia el estado de alguna de tus facturas.</h3>
                  <h3 style={{fontWeight: 'normal'}}><br></br>Rechazada: contacta con tu cliente lo antes posible para aclarar y volver a facturar sin perder tiempo.</h3>
                  <h3 style={{fontWeight: 'normal'}}><br></br>Aceptada: tu cliente está de acuerdo y sólo debes ocuparte de recibir el pago.</h3>
                  <h3 style={{fontWeight: 'normal'}}><br></br>Pagada: te notificamos cada vez que recibas un abono por el monto de una factura pendiente, para que ahorres tiempo en conciliación y mantengas el orden.<br></br></h3>
               </TituloGeneral>

               <TituloGeneral partedeprofi>
                  <p style={{fontSize: '18px'}}>Facturas recibidas</p>
                  <h3 style={{fontWeight: 'normal'}}><br></br>Entérate al momento en que te emitan una factura y revísala. Recuerda que si hay alguna discrepancia y no la rechazas dentro del plazo, será aceptada automáticamente por el SII.</h3>
                  <h3 style={{fontWeight: 'normal'}}><br></br>Para poder hacer uso del crédito fiscal y reducir el IVA del mes, debes aceptar manualmente las facturas recibidas en los últimos días.<br></br><br></br></h3>
               </TituloGeneral>



            </Wrapper>

         </Section>

         <Section id="tienesdudas" tienesdudas>

            <Wrapper>
               <TituloGeneral partedeprofi>
                  <p>Conversemos</p>
                  <h2>¿Aún tienes dudas?</h2>
               </TituloGeneral>


               <TienesDudasContenido>

                  <TienesDudasImagen>
                     <img src={tienesdudasimagen} alt="tienesdudasimagen" />

                     <TienesDudasImagenText amarillo>
                        Crea tu cuenta gratis y accede desde cualquier dispositivo
                     </TienesDudasImagenText>

                     <TienesDudasImagenText azul>
                        Nunca vuelvas a dejar una factura sin gestionar
                     </TienesDudasImagenText>

                     <TienesDudasImagenText rojizo>
                        Ahorra capital de trabajo y ordena tus finanzas
                     </TienesDudasImagenText>



                  </TienesDudasImagen>

                  <TienesDudasFormulario>

                     <h2>
                        Nos encantaría responder tus preguntas
                     </h2>

                     <ul>
                        <li><BsCheckCircle></BsCheckCircle> <p>Envíanos un correo a <a href="mailto:contacto@profi.cl">contacto@profi.cl</a></p></li>
                        {/*<li><BsCheckCircle></BsCheckCircle> <p>Agenda un reunión con nosotros <a href="https://calendly.com/contactoprofi/general" target="_blank" rel="noopener noreferrer" >aquí</a></p></li>*/}
                        <li><BsCheckCircle></BsCheckCircle> <p>Whatsapp: <a href="tel:+56932975573">+56 9 3297 5573</a></p></li>
                        {/*<li><BsCheckCircle></BsCheckCircle> <p>Si prefieres que nosotros te contactemos</p></li>*/}
                     </ul>

                     {/*<form action="" onSubmit={handleSubmit}>

                        <div className="tienesdudas_formulario-inputs">
                           <input type="text" placeholder='Nombre' name="nombre" id="" required />
                           <input type="text" placeholder='Correo' name="correo" id="" required />
                           <input type="text" placeholder='Teléfono (opcional)' name="telefono" id="" />
                           <input type="text" placeholder='Mensaje (opcional)' name="mensaje" id="" />
                        </div>

                        <button type="submit">
                           Enviar
                        </button>

                     </form>*/}

                  </TienesDudasFormulario>



               </TienesDudasContenido>

            </Wrapper>

         </Section>

         <ToastContainer />

      </>

   )
};

export default Home;
