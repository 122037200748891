import React, { useState } from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './components/home/Home';


function App() {

  const [modalcons, setModalcons] = useState(false)
  const handleCloseModal = () => {
    setModalcons(false)
  }

  return (
    <>
      <Navbar abrirModalCons={setModalcons}></Navbar>
      <Home abrirModalCons={setModalcons}></Home>
      <Footer></Footer>
      {
        modalcons && (
          <div className="modal-en-creacion" onClick={handleCloseModal}>
            <div className='modal-en-creacion-contenido' onClick={(ev) => ev.stopPropagation()}>

              <div className='modal-en-creacion-texto'>Nuestra plataforma web se encuentra en construcción, si tienes alguna duda podemos comunicarnos contigo de forma inmediata</div>
              
              <div className='modal-en-creacion-boton'>
                <a href="/#tienesdudas" onClick={handleCloseModal}>Contáctanos</a>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

export default App;
