import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import IngresaBanco from './IngresaBanco';
import IngresaSI from './IngresaSI';
import {
  BrowserRouter, Routes,
  Route,
} from "react-router-dom";
import TagManager from 'react-gtm-module'
import TerminosCondiciones from './components/TerminosCondiciones';
import PoliticasPrivacidad from './components/PoliticasPrivacidad';
const tagManagerArgs = {
  gtmId: 'GTM-NCXC27C'
}

TagManager.initialize(tagManagerArgs)
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="ingresa-banco" element={<IngresaBanco />} />
        <Route path="ingresa-sii" element={<IngresaSI />} />
        <Route path="/terminos-y-condiciones" element={<TerminosCondiciones />} />
        <Route path="/politica-de-privacidad" element={<PoliticasPrivacidad />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
  ,
  document.getElementById('root')
);
