import React from 'react'

import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

import styled, { css } from 'styled-components'

const Container = styled.div`
   max-width:1000px ;
   margin: 50px auto ;
   padding: 0 20px ;

`
const Negrita = styled.strong`

   font-family: Ubuntu-Bold;
   color: #3E3E3E;

`
const Parrafo = styled.p`

   padding:initial ;
   margin: 1em 0;
   font-family: branding-medium;
    color: #3E3E3E;
   
   ${props => props.center && css`
      text-align:center ;
   `}

`
const Lista = styled.ul`
   padding-left: 20px;
   list-style: upper-alpha;

   & li{
      padding-left: 5px;
   }
`

const PoliticasPrivacidad = () => {
   return (
      <>
         <Navbar></Navbar>

         <Container>

            <Parrafo center>
               <Negrita >POLÍTICA DE PRIVACIDAD</Negrita>
            </Parrafo>

            <Parrafo>
               Mediante la presente política de privacidad, el usuario de la página web www.profi.cl (“Usuario”), la entidad que contrate un financiamiento con Profi SpA (“Profi”), (“Cliente”), así como la persona natural que represente a un Cliente, según corresponda, declara ser mayor de edad y consiente expresamente en que toda la información que proporcione a Profi identificada o identificable con su persona, en adelante, los “Datos Personales”, podrá ser objeto de un tratamiento por parte de Profi.
            </Parrafo>

            <Parrafo>
               El titular de los datos podrá en todo momento ejercer los derechos contemplados por la Ley 19.628 tales como acceder en cualquier   tiempo a sus Datos Personales, modificarlos, rectificarlos, actualizarlos o revocar su consentimiento para su uso, enviando para estos efectos un correo electrónico a contacto@profi.cl.
            </Parrafo>

            <Parrafo>
               Profi será el responsable de los Datos Personales, siendo esta política la que regula la forma y las finalidades con las que estos serán usados. Profi solo podrá realizar este tratamiento si el Usuario y/o Cliente así lo autoriza de manera expresa y por ello es esencial que revises en detalle esta política y la aceptes solo si estás de acuerdo con ella y antes de entregar cualquier dato a Profi.
            </Parrafo>

            <Parrafo>
               <Negrita>
                  1.	DATOS PERSONALES REQUERIDOS
               </Negrita>
            </Parrafo>

            <Parrafo>
               Los Datos Personales que podrán ser requeridos al Usuario o Cliente o bien que serán recolectados por Profi de fuentes de dominio público son los siguientes:
            </Parrafo>

            <Lista>
               <li>Nombre completo</li>
               <li>Fecha de nacimiento </li>
               <li>Rut</li>
               <li>Domicilio</li>
               <li>Profesión y cargo que ostenta en la persona jurídica</li>
               <li>Correo electrónico y teléfono de contacto</li>
               <li>Información financiera, crediticia, bancaria del Cliente o Usuario y sus ingresos</li>
               <li>Cuenta corriente bancaria del Cliente</li>
               <li>Información del dispositivo electrónico a través del cual accedes a tu cuenta en el sitio web de Profi (“Cuenta Profi”). </li>
               <li>Información sobre el uso de la Cuenta Profi (tiempo de uso, visitas, etc.).</li>
               <li>Dirección IP de internet utilizada para navegar en el sitio www.profi.cl</li>
               <li>Información de geolocalización </li>
            </Lista>

            <Parrafo>
               Los Datos Personales son almacenados en registros o bancos de datos de propiedad de Profi, que no son accesibles al público. Sin embargo, Profi está facultada para contratar servicios de tratamiento y análisis de Datos Personales a terceros, los que actuarán en calidad de mandatarios y bajo confidencialidad y que utilizarán los datos con la finalidad descrita en esta política de privacidad.
            </Parrafo>

            <Parrafo>
               <Negrita>
                  2.	FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES
               </Negrita>
            </Parrafo>

            <Parrafo>
               El titular de los Datos Personales acepta expresamente por este acto que Profi utilice los Datos Personales con la finalidad de:
            </Parrafo>

            <Lista>
               <li>Crear y administrar la Cuenta Profi y gestionar medidas de seguridad necesarias.</li>
               <li>Verificar la identidad del Usuario y/o Cliente y la validez de los documentos entregados por ellos a través de la página web de Profi.</li>
               <li>Realizar una oferta de financiamiento a medida y entregar un financiamiento, según corresponda. Además, Profi podrá utilizar los Datos Personales para lograr una buena gestión y administración del sitio web, para poder prestar, ofrecer, y mejorar la experiencia del usuario y la oferta de sus productos; elaborar perfiles de acuerdo al análisis crediticio; para actualizar la información sobre los productos ofrecidos por Profi; para enviar encuestas al Usuario y/o Cliente; y para remitir al Usuario y/o Cliente  comunicaciones comerciales o publicitarias por correo electrónico u otros sistemas de mensajería, sin perjuicio de utilizar estos datos con la finalidad de cumplir con la normativa legal aplicable y con los requerimientos de información solicitados por autoridades administrativas o judiciales competentes.</li>
               <li>Para contactarte en relación a la oferta de financiamiento o el financiamiento mismo.</li>
               <li>Para cobrar el financiamiento judicial o extrajudicialmente en el caso que corresponda.</li>

               <li>Para exigir el cumplimiento de los Términos y Condiciones aceptados por ti (https://profi.cl/terminos-y-condiciones).</li>
               <li>Para finalidades de marketing y publicidad.</li>
               <li>Para dar cumplimiento a necesidades legítimas del negocio de Profi, tales como aquellas derivadas de un proceso de compra o fusión de Profi o bien para proteger sus derechos o los de algún otro Usuario y/o Cliente.</li>
            </Lista>

            <Parrafo>
               Los Datos Personales serán almacenados y tratados por Profi por todo el tiempo que sea necesario para cumplir la finalidad descrita en este numeral.
            </Parrafo>

            <Parrafo>
               Asimismo, Profi garantiza que en ninguna circunstancia transferirá, cederá o proveerá los Datos Personales del Usuario o de las personas naturales que correspondan a terceras personas, a menos que ello sea ordenado por un tribunal de justicia o autoridad administrativa competente de acuerdo con la legislación vigente, o que el propio titular de los datos lo autorice.
            </Parrafo>

            <Parrafo>
               Sin perjuicio de lo anterior, Profi podrá compartir Datos Personales con terceros proveedores de servicios o encargados de la cobranza de los créditos o con las sociedades relacionadas a Profi o con terceros prestadores de servicios contratados por estas últimas; terceros quienes en todo caso actuarán en calidad de mandatarios y bajo confidencialidad y con la sola finalidad descrita en este numeral de la política de privacidad. También Profi podría compartir los datos en caso de que se venda o transfiera parcial o totalmente los activos de Profi. En todos los casos anteriores se compartirán los datos necesarios para la gestión o servicio correspondiente.
            </Parrafo>

            <Parrafo>
               <Negrita>
                  3. DE LAS COOKIES O ETIQUETAS DE PIXELES
               </Negrita>
            </Parrafo>

            <Parrafo>
               Por cookies se entiende unos pequeños paquetes de datos que se intercambian entre programas informáticos y que se crean en un terminal (por ejemplo, teléfonos móviles, notebook, etcétera) al visitar una página web, y que son utilizadas con diversas finalidades, como por ejemplo, (i) optimizar el funcionamiento de páginas web, (ii) mejorar las preferencias del usuario, (iii) almacenar las preferencias de quienes visitan páginas web, (vi) recopilar información relacionada a la visitas efectuadas por usuarios en páginas web para fines de rendimiento y análisis; y, (iv) gestionar la publicidad incluida en páginas web.
            </Parrafo>

            <Parrafo>
               Específicamente, las cookies contienen lo que se denomina un “ID de cookie”, que es un identificador unívoco de la cookie que se compone de una secuencia de caracteres, por medio de los cuales se asignan las páginas web y servidores al navegador de Internet concreto en el que se almacena la cookie, permitiendo a las páginas web y servidores visitados diferenciar el navegador que utiliza una persona individual de otros navegadores de Internet que contengan otras cookies. De esta manera, un navegador de Internet determinado puede reconocerse e identificarse por medio de un ID de cookie unívoco.
            </Parrafo>

            <Parrafo>
               El Usuario al visitar www.profi.cl acepta que Profi haga uso de las cookies con los siguientes objetivos: (i) que la navegación por el sitio web sea más segura, (ii) que el sitio web comprenda opciones personalizadas, (iii) analizar el tráfico entrante al sitio web y (iv) mejorar la experiencia del Usuario.
            </Parrafo>

            <Parrafo>
               En el caso de que el Usuario desee borrar las cookies depositadas en un terminal puede hacerlo en todo momento, ingresando a la opción de configuración que disponga para ello su navegador de Internet.
            </Parrafo>

            <Parrafo>
               <Negrita>4.	ENLACES A OTROS SITIOS WEB</Negrita>
            </Parrafo>

            <Parrafo>
               A través del sitio web www.profi.cl, Profi podría incluir enlaces a sitios web de terceros, lo cual no indica que sean propiedad de, o sean operados por Profi. Se aclara que el procesamiento de la información personal que hagan los terceros responsables de los sitios web antes mencionados no está alcanzado por esta política de privacidad. Asimismo, al aceptar esta política reconoces y aceptas que Profi no tiene control sobre tales sitios web y no es ni será responsable por el contenido ni por los servicios prestados por tales sitios, ni por el modo en que éstos procesen tu información personal, por lo que ingresas a dichos sitios web bajo tu propia responsabilidad.
            </Parrafo>

            <Parrafo>
               <Negrita>5. CAMBIOS EN LA DECLARACIÓN DE PRIVACIDAD </Negrita>
            </Parrafo>

            <Parrafo>
               Profi podrá realizar actualizaciones a esta política de privacidad para reflejar los cambios constantes en los servicios que ofrecemos. Te notificaremos de estos cambios por nuestros canales habituales, como el correo electrónico o mensajes a través de las aplicaciones. En los casos que corresponda, recabaremos tu consentimiento.

               Para cualquier duda respecto a esta política comercial por favor contáctanos a contacto@profi.cl.
            </Parrafo>

         </Container>

         <Footer></Footer>
      </>
   )
}

export default PoliticasPrivacidad